.search-page {
  padding-top: 40px;
}

.search-button {
  .search-page & {
    margin-inline-start: 34px;
    width: 2em;

    .portrait & {
      margin-inline-start: 24px;
    }
  }
}

.search-inputs {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.search-inputs__codigo {
  display: flex;
  max-width: 320px;

  .portrait & {
    width: 100%;
    justify-content: space-between;
  }
}

.reservas-list {
  margin-block-start: 40px;
  overflow-y: scroll;

}

.reservas-list__item {
  margin-block-end: 24px;
}