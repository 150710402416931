*,
*::before, *::after {
    /* Utility */
    box-sizing: border-box;
}
html {
    /* Typography */
    font-size: 20px; // Setting a base font size for rem calculations
}
body {
    @include app-variables();
    // @include font-poppins(var(--base-font-size), var(--base-line-height));

    /* Box Model */
    margin    : 0;
    min-height: 100vh;

    /* Other */
    background-color: var(--color--gris);
}
p {
    /* Box Model */
    margin: 0 0 var(--base-line-height);
}
%label {
    &--text-and-icon {
        /* Flexbox */
        display        : flex;
        flex-direction : row;
        justify-content: space-between;
        align-items    : center;
    }
}