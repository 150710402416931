.reserva-card {
  width: 100%;
  display: flex;
}

.reserva-card__content {
  flex: 1;
}

.reserva__codigo {
  // width: 100px;
}
.reserva__nombre {

}
.reserva__programa {

}
.reserva__estado {

}

.reserva-card__actions {
  // width: fit-content;
}

.check-button {
  .reserva-card & {
    // border-radius: 50%;
    width: 42px;
    height: 42px;
    padding: 0;
    margin: 0;
    min-width: auto;
  }
}