.login-page {
  display: flex;
  justify-content: center;
  align-items: center;

  --inputs-height : 135px;
}

.login-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

}

.form-inputs {
  height: var(--inputs-height);
  width: 432px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline-end: 52px;
}

.usuario-input {

}

.password-input {

}

.login-button {
  width: 215px;
  height: var(--inputs-height);
}