.main-container {
  --app--status-bar-height: 48px;

  min-height: 100vh;
}

.main-content {
  .main-container & {
    position: relative;
    height: calc(100vh - var(--app--status-bar-height));
    // padding-inline: 24px;
  }

}