header.status-bar {
  // background-color: var(--color--primary);
  // color: white;
  height: var(--app--status-bar-height);
  text-transform: uppercase;
}

.status-bar__title {
  flex: 1;
}

.status-bar__exit {
  .status-bar & {
    padding: 0;
  }
}