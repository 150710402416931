@use 'colors' as *;

@mixin app-variables() {
    /* DEFAULT COLOUR PALETTE */
    --color--primary         : #{$color--primary};
    --color--primary-accent  : #{$color--primary-accent};
    --color--secondary       : #{$color--secondary};
    --color--accent          : #{$color--accent};
    --color--gris            : #{$color--gris};

    /* TYPOGRAPHY */
    --base-font-size         : 1.2rem;
    --base-line-height       : 1.5rem;
    --base-line-height-halved: 0.8rem;

    /* LAYOUT */
    --base--padding  : var(--base-line-height-halved);
    --app-bar--height: calc( var(--base-line-height) * 3 );
    --cta-bar--height: calc( var(--base-line-height) * 4 );
}