.reserva-page {
  // padding-top: 40px;
}
.reserva-page {

}

.reserva-content {
  .reserva-page & {
    margin: 0;
    height: 100%;
  }
}

.seccion-content {
  flex: 1;
  height: calc(100vh - (2 * var(--app--status-bar-height)));
}

.MuiTabs-flexContainerVertical {
  .reserva-content & {
    .reserva-page & {
      height: 100%;
    }
  }
}